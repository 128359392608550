import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
// import Actualites from '../components/sections/actualites'

const ActualitePage = () => (
  <StaticQuery
    query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        return (
          <Layout>
            <Helmet
              title={`Actualités & évènement | ${data.site.siteMetadata.title}`}
              meta={[
                { name: 'description', content: 'Retrouve les actualités et évènements à venir au gîte de la grange à outeaux et ne manquez pas les activités de la région de Bourg-en-Bresse.' },
              ]}
            />
            { /* <Actualites /> */}
          </Layout>
        )
      }
  } />
)

export default ActualitePage
